export function columnListIn(){
  const listItem = document.getElementsByClassName('column-list_item');
  let displayCount = 1;

  for (let i = 0, len = listItem.length; i < len; i++) {
    let elm = listItem[i]
    let setTime;

    if (displayCount * 100 <= 2000) {
      setTime = displayCount * 100;
    } else {
      setTime = 2000;
    }

    elm.style.setProperty('display', 'block');

    setTimeout(function () {
      elm.classList.add('display-item');
    }, setTime)

    displayCount++;
  }

}