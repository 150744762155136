const listItem = document.getElementsByClassName('company-list_item');

let displayCount = 1;

function displayJudge(loopVar) {
    let isRecruit;

    if (document.body.classList.contains('mode-recruit')){
        isRecruit =
            listItem[loopVar].dataset.typeDisplay === '1' &&
            listItem[loopVar].dataset.areaDisplay === '1' &&
            listItem[loopVar].dataset.recruitDisplay === '1' &&
            listItem[loopVar].dataset.establishmentDisplay === '1'&&
            listItem[loopVar].dataset.capitalDisplay === '1' &&
            listItem[loopVar].dataset.ratingDisplay === '1';
    }
    else{
        isRecruit =
            listItem[loopVar].dataset.typeDisplay === '1' &&
            listItem[loopVar].dataset.areaDisplay === '1' &&
            listItem[loopVar].dataset.establishmentDisplay === '1' &&
            listItem[loopVar].dataset.capitalDisplay === '1' &&
            listItem[loopVar].dataset.ratingDisplay === '1';

    }

    if (isRecruit) {
        let setTime;

        if (displayCount*100 <= 2000){
            setTime = displayCount*100;
        }
        else {
            setTime = 2000;
        }

        listItem[loopVar].style.setProperty('display' , 'block');
        setTimeout(function () {
            listItem[loopVar].classList.add('display-item');
        },setTime)

        displayCount++;
    }
}

function hideAll() {
    displayCount = 1;

    for (let i = 0; i < listItem.length; i++){
        listItem[i].style.setProperty('display' , null);
        listItem[i].classList.remove('display-item');
    }
}


/*-------------------------------------------*/
/*	ページ読み込み時
/*-------------------------------------------*/
export function initialJudge(){
    const areaCheckboxes = document.getElementsByClassName('form_area_checkbox');
    const typeCheckboxes = document.getElementsByClassName('form_type_checkbox');

    // 投稿数カウント＆絞り込みに件数セット
    setTimeout(function () {
        for (let i = 0; i < listItem.length; i++) {
            let setComplete = '';

            let itemType = listItem[i].dataset.area;

            for (let j = 0; j < areaCheckboxes.length; j++) {
                if (areaCheckboxes[j].value === itemType) {
                    let checkbox_label = document.querySelector('label[for="' + areaCheckboxes[j].id + '"]');
                    let article_count = checkbox_label.getElementsByClassName('article_count')[0].innerHTML;

                    let new_count = parseInt(article_count);

                    checkbox_label.getElementsByClassName('article_count')[0].innerHTML = new_count += 1;

                    setComplete = 'set!';
                }
            }

            if (setComplete === '' && document.getElementById('archive') !== null){
                if (document.body.classList.contains('archive')){
                    console.warn(
                        'Item set failed by area: ' +  listItem[i].getElementsByClassName('company-list_siteName')[0].innerHTML
                    )
                }
            }
        }
    })

    setTimeout(function () {
        for (var i = 0; i < listItem.length; i++) {
            var itemType = listItem[i].dataset.type;

            for (var j = 0; j < typeCheckboxes.length; j++) {
                if (typeCheckboxes[j].value === itemType) {
                    var checkbox_label = document.querySelector('label[for="' + typeCheckboxes[j].id + '"]');
                    var article_count = checkbox_label.getElementsByClassName('article_count')[0].innerHTML;

                    var new_count = parseInt(article_count);

                    checkbox_label.getElementsByClassName('article_count')[0].innerHTML = new_count += 1;
                }
            }
        }
    })
}


/*-------------------------------------------*/
/*	形態
/*-------------------------------------------*/
export function typeFilter() {
    const typeCheckboxes = document.getElementsByClassName('form_type_checkbox');

    if(typeCheckboxes){
        for (let k = 0; k < typeCheckboxes.length; k++) {
        typeCheckboxes[k].addEventListener('click', function () {
            hideAll();

            setTimeout(function () {
                for (let i = 0; i < listItem.length; i++) {
                    let itemType = listItem[i].dataset.type;

                    for (var j = 0; j < typeCheckboxes.length; j++) {
                        if (typeCheckboxes[j].value === itemType) {
                            if (typeCheckboxes[j].checked) {
                                listItem[i].dataset.typeDisplay = '1';
                            } else {
                                listItem[i].dataset.typeDisplay = '0';
                            }
                        }
                    }

                    displayJudge(i);
                }

            })
        });
    }
    }
}


/*-------------------------------------------*/
/*	創業年数
/*-------------------------------------------*/
function establishmentJudge(boxId,loopOrder) {
    if (document.getElementById(boxId).checked) {
        listItem[loopOrder].dataset.establishmentDisplay = '1';
    } else {
        listItem[loopOrder].dataset.establishmentDisplay = '0';
    }
}

export function establishmentFilter() {
    const establishmentCheckboxes = document.getElementsByClassName('form_establishment_checkbox');

    if (establishmentCheckboxes){
        for (let k = 0; k < establishmentCheckboxes.length; k++) {

        establishmentCheckboxes[k].addEventListener('click', function () {
            hideAll();

            setTimeout(function () {
                for (var i = 0; i < listItem.length; i++) {
                    var today = new Date().getFullYear();
                    var itemEstablishment = today - parseInt(listItem[i].dataset.establishment);

                    if (listItem[i].dataset.establishment === ""){
                        establishmentJudge('form_establishment_00',i);
                    }
                    else if (itemEstablishment < 1){
                        establishmentJudge('form_establishment_06',i);
                    }
                    else if (itemEstablishment < 5){
                        establishmentJudge('form_establishment_05',i);
                    }
                    else if (itemEstablishment < 10){
                        establishmentJudge('form_establishment_04',i);
                    }
                    else if (itemEstablishment < 20){
                        establishmentJudge('form_establishment_03',i);
                    }
                    else if (itemEstablishment < 30){
                        establishmentJudge('form_establishment_02',i);
                    }
                    else if (itemEstablishment >= 30){
                        establishmentJudge('form_establishment_01',i);
                    }
                    else{
                        listItem[i].dataset.establishmentDisplay = '0';
                    }

                    displayJudge(i);
                }
            })
        });
    }
    }
}

export function historyFilterAllControl() {
    const allCheck = document.getElementById('form_historyAllCheck');
    const allUncheck = document.getElementById('form_historyAllUncheck');
    const historyCheckboxes = document.getElementsByClassName('form_establishment_checkbox');

    if ( allCheck && allUncheck ){
        allCheck.addEventListener('click',function () {
            for (var i = 0; i < historyCheckboxes.length; i++){
                historyCheckboxes[i].checked = 'checked';
            }

            hideAll();
            for (var k = 0; k < listItem.length; k++){
                listItem[k].dataset.establishmentDisplay = '1';
                displayJudge(k);
            }
        })

        allUncheck.addEventListener('click',function () {
            for (var i = 0; i < historyCheckboxes.length; i++){
                historyCheckboxes[i].checked = null;
            }

            hideAll();
            for (var k = 0; k < listItem.length; k++){
                listItem[k].dataset.establishmentDisplay = '0';
                displayJudge(k);
            }
        })
    }
}



/*-------------------------------------------*/
/*	資本金
/*-------------------------------------------*/
export function capitalFilter() {
    const capitalCheckboxes_wrap = document.getElementById('form_capital');
    const capitalCheckboxes = document.getElementsByClassName('form_capital_checkbox');

    if (capitalCheckboxes_wrap){
        capitalCheckboxes_wrap.addEventListener('change',function (){
        hideAll();

        for (let i = 0,len = listItem.length; i < len; i++){
            let elm = listItem[i];
            let capitalVal = elm.dataset.capital;
            if (capitalVal && capitalVal !== '非公開') capitalVal = Number(capitalVal);
            elm.dataset.capitalDisplay = '0';

            for (let j = 0,len = capitalCheckboxes.length; j < len; j++){
                let elmCheck = capitalCheckboxes[j];

                let checkValue = elmCheck.value;

                if (elmCheck.checked !== true) continue;

                if (checkValue.indexOf('~') > 0) {
                    let range = checkValue.split('~');
                    let rangeStart = range[0];
                    let rangeEnd = range[1];

                    if ( typeof capitalVal === 'number' && rangeStart <= capitalVal && capitalVal <= rangeEnd) elm.dataset.capitalDisplay = '1';
                }
                else if ( checkValue === 'unset' ) {
                    if (!capitalVal || capitalVal === '非公開') elm.dataset.capitalDisplay = '1';
                }

            }

            displayJudge(i);
        }
    })
    }
}

export function capitalFilterAllControl() {
    const allCheck = document.getElementById('form_capitalAllCheck');
    const allUncheck = document.getElementById('form_capitalAllUncheck');
    const historyCheckboxes = document.getElementsByClassName('form_capital_checkbox');

    if( allCheck && allUncheck ){
        allCheck.addEventListener('click',function () {
            for (let i = 0; i < historyCheckboxes.length; i++){
                historyCheckboxes[i].checked = 'checked';
            }

            hideAll();
            for (let k = 0; k < listItem.length; k++){
                listItem[k].dataset.capitalDisplay = '1';
                displayJudge(k);
            }
        })

        allUncheck.addEventListener('click',function () {
            for (let i = 0; i < historyCheckboxes.length; i++){
                historyCheckboxes[i].checked = null;
            }

            hideAll();
            for (let k = 0; k < listItem.length; k++){
                listItem[k].dataset.capitalDisplay = '0';
                displayJudge(k);
            }
        })
    }
}


/*-------------------------------------------*/
/*	Google レビュー
/*-------------------------------------------*/
export function ratingFilter() {
    const ratingCheckboxes_wrap = document.getElementById('form_rating');

    if (ratingCheckboxes_wrap){
        ratingCheckboxes_wrap.addEventListener('change',function (){
            hideAll();
            let display_range_vals = ratingCheckboxes_wrap.rating.value;
            if ( display_range_vals.match('~') ) {
                display_range_vals = ratingCheckboxes_wrap.rating.value.split('~');
            }

            for (let i = 0,len = listItem.length; i < len; i++){
                let elm = listItem[i];
                let ratingVal = elm.dataset.rating;
                if (ratingVal && ratingVal !== 'null') ratingVal = Number(ratingVal);
                elm.dataset.ratingDisplay = '0';

                if ( display_range_vals === 'null' ){
                    elm.dataset.ratingDisplay = '1';
                }
                else if ( display_range_vals[0] <= ratingVal && ratingVal <= display_range_vals[1]){
                    elm.dataset.ratingDisplay = '1';
                }

                displayJudge(i);
            }
        })
    }
}



/*-------------------------------------------*/
/*	エリア
/*-------------------------------------------*/
const areaCheckboxes = document.getElementsByClassName('form_area_checkbox');

export function areaFilter() {
    if (areaCheckboxes) {
        for (var k = 0; k < areaCheckboxes.length; k++) {
            areaCheckboxes[k].addEventListener('click', function () {
                hideAll();

                setTimeout(function () {
                    for (var i = 0; i < listItem.length; i++) {
                        var itemType = listItem[i].dataset.area;

                        for (var j = 0; j < areaCheckboxes.length; j++) {
                            if (areaCheckboxes[j].value === itemType) {
                                if (areaCheckboxes[j].checked) {
                                    listItem[i].dataset.areaDisplay = '1';
                                } else {
                                    listItem[i].dataset.areaDisplay = '0';
                                }
                            }
                        }

                        displayJudge(i);
                    }
                })
            });
        }
    }
}

export function areaFilterAllControl() {
    const allCheck = document.getElementById('form_allCheck');
    const allUncheck = document.getElementById('form_allUncheck');

    if( allCheck && allUncheck ){
        allCheck.addEventListener('click',function () {
            for (let i = 0; i < areaCheckboxes.length; i++){
                areaCheckboxes[i].checked = 'checked';
            }

            hideAll();
            for (let k = 0; k < listItem.length; k++){
                listItem[k].dataset.areaDisplay = '1';
                displayJudge(k);
            }
        })

        allUncheck.addEventListener('click',function () {
            for (let i = 0; i < areaCheckboxes.length; i++){
                areaCheckboxes[i].checked = null;
            }

            hideAll();
            for (let k = 0; k < listItem.length; k++){
                listItem[k].dataset.areaDisplay = '0';
                displayJudge(k);
            }
        })
    }
}
