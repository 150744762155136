import * as searchFilter from './modules/search_filter';
import {columnListIn} from "./modules/columnListIn";

function slideMenu() {
    const slideMenu = document.getElementById('slide-menu');
    const slideMenu_switch = document.getElementById('slide-menu_switch');

    if (document.getElementById('slide-menu')){
        slideMenu_switch.addEventListener('click',function () {
            slideMenu.classList.toggle('open');
            slideMenu_switch.classList.toggle('open');
        })
    }
}

function modeSwitch() {
    const companyBtn = document.getElementById('modeSwitch_company');
    const recruitBtn = document.getElementById('modeSwitch_recruit');
    const currentClass = 'current';

    if (companyBtn){
        companyBtn.addEventListener('click', function () {
            if (!this.classList.contains(currentClass)){
                this.classList.add(currentClass);
                recruitBtn.classList.remove(currentClass)
                document.body.classList.remove('mode-recruit')
            }

            searchFilter.initialJudge();
        })
    }

    if (recruitBtn){
        recruitBtn.addEventListener('click', function () {
            if (!this.classList.contains(currentClass)){
                this.classList.add(currentClass);
                companyBtn.classList.remove(currentClass);
                document.body.classList.add('mode-recruit')
            }

            searchFilter.initialJudge();
        })
    }
}

function scrollTop(){
    const btn = document.getElementById('scrollTop');
    var scrollTop;

    window.addEventListener('scroll',function () {
        scrollTop = window.pageYOffset;

        if (scrollTop >= 600){
            btn.style.opacity = '1';
            btn.style.pointerEvents = null;
        }else {
            btn.style.opacity = '0';
            btn.style.pointerEvents = 'none';
        }

    })


    btn.addEventListener('click',function () {
        window.scrollTo({
            top:0,
            behavior: "smooth",
        });
    })

}

function BbsPosition(){
    let bbsContents = document.getElementsByClassName('singleContent_blog');
    var rightCulumn = document.getElementsByClassName('singleContent_r');

    if (window.matchMedia('(max-width: 767px)').matches) {

        rightCulumn[0].appendChild(
            bbsContents[0]
        );

        
    }
}





scrollTop();
searchFilter.initialJudge();

//BBSを取得しているページのみで実行
if (
    document.getElementsByClassName('singleContent_blog') &&
    document.body.classList.contains('single')
){
    BbsPosition();
}

//トップ・県別一覧でのみ実行
if (
    document.body.classList.contains('home') ||
    document.body.classList.contains('archive')
){
    slideMenu();
    modeSwitch();
    searchFilter.typeFilter();
    searchFilter.areaFilter();
    searchFilter.ratingFilter();
    searchFilter.areaFilterAllControl();
    searchFilter.historyFilterAllControl();
    searchFilter.establishmentFilter();
    searchFilter.capitalFilter();
    searchFilter.capitalFilterAllControl();
}

if ( document.body.classList.contains('column') ) {
    columnListIn();
}
